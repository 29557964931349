<div class="d-flex flex-wrap w-100 mnh-100vh flex-wrap-reverse ps-2 pe-2 ps-sm-3 pe-sm-3 pe-md-0 ps-md-4 ps-lg-5">
    <!-- INFO Hero text, so to speak -->
    <div class="d-flex flex-column col-12 col-md-6 col-lg-5">
        <!-- INFO Hero Main Text -->
        <div class="d-flex flex-column mt-auto">
            <span class="mb-0 mat-headline-1 text-primary fw-bold text-left">Right energy</span>
            <span class="mb-0 mat-headline-1 text-primary fw-bold text-left">ready for you</span>
        </div>

        <!-- INFO Hero Caption -->
        <span class="mt-4e mat-headline-4">Manage, conserve, track and pay for your energy in one click.</span>

        <!-- INFO Request Demo -->
        <div class="d-flex w-100 mnw-sm-fit-content w-xl-75 mt-4 mb-auto px-3 py-2 border border-1 rounded-1 bg-white">
            <div class="d-flex w-100">
                <!-- INFO Input Wrapper -->
                <!-- INFO Icon wrapper -->
                <div class="d-flex h-fit-content me-1 my-auto">
                    <mat-icon fontSet="material-symbols-outlined">mail_envelope</mat-icon>
                </div>

                <!-- INFO Input field -->
                <input class="w-100 h-100 ms-3 me-3 my-auto fw-bold border-0 mat-body-1 bg-transparent" type="text" [formControl]="form"
                    placeholder="mail@email.com">
            </div>

            <!-- href="mailto:hello@evryword.com.ng?cc={{'olaleyeisrael@gmail.com'}}&subject={{'Request for Demo'}}&body={{'I would like to request for a demo'}}" -->
            <button mat-flat-button class="my-auto mnw-fit-content px-3 py-4" color="primary" [disabled]="form.invalid" (click)="sendDemoRequest()">
                <span class="w-100 my-auto fw-bold">Request demo</span>
            </button>
        </div>
    </div>

    <!-- INFO Hero Image -->
    <div class="d-none d-md-flex col-12 col-md-6 col-lg-7 mb-4 mb-sm-0 ps-0">
        <div class="d-flex w-100 h-100 position-relative overflow-x-hidden overflow-y-hidden">
            <!-- INFO Hero Image -->
            <img class="w-sm-320px w-md-520px w-lg-720px w-xl-820px ms-auto mt-auto z-index-1"
                src="https://storage.googleapis.com/gci-static-assets/imole/imole-hero-image.png" alt="Human logo"
                style="object-fit: contain;">

            <!-- INFO background vector -->
            <svg class="w-sm-320px w-md-520px w-lg-720px w-xl-820px right-m92px bottom-sm-0 position-absolute" viewBox="0 0 630 662"
                fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(15deg); flex-shrink: 0;">
                <path
                    d="M316.246 383.235L234.308 430.542L68.8379 386.204L103.469 256.958L268.939 301.296L316.246 383.235Z"
                    fill="#FDEB01" />
                <path
                    d="M430.464 581.067L386.118 746.57L256.838 711.93L301.185 546.427L383.123 499.119L383.157 499.128L430.464 581.067Z"
                    fill="#FDEB01" />
                <path
                    d="M558.415 103.546L514.078 269.015L432.139 316.323L432.106 316.314L384.798 234.375L429.136 68.9053L558.415 103.546Z"
                    fill="#FDEB01" />
                <path d="M384.81 234.342L360.31 325.779L268.94 301.297L183.298 152.91L299.183 86.0319L384.81 234.342Z"
                    fill="#FDEB01" />
                <path
                    d="M325.679 455.024L301.188 546.427L152.859 632.122L85.9569 516.194L234.31 430.542L325.679 455.024Z"
                    fill="#FDEB01" />
                <path d="M746.453 429.247L711.822 558.492L546.318 514.146L499.011 432.207L580.95 384.9L746.453 429.247Z"
                    fill="#FDEB01" />
                <path
                    d="M430.46 581.067L386.113 746.57L256.834 711.93L301.18 546.426L383.119 499.119L383.152 499.128L430.46 581.067Z"
                    fill="#FDEB01" />
                <path
                    d="M558.411 103.545L514.073 269.015L432.135 316.322L432.101 316.313L384.794 234.375L429.131 68.9048L558.411 103.545Z"
                    fill="#FDEB01" />
                <path d="M729.346 299.224L580.95 384.9L489.581 360.418L514.081 268.981L662.425 183.363L729.346 299.224Z"
                    fill="#FDEB01" />
                <path
                    d="M632.004 662.508L516.101 729.454L430.458 581.067L454.949 489.664L546.319 514.146L632.004 662.508Z"
                    fill="#FDEB01" />
            </svg>
        </div>
    </div>
</div>
