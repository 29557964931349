<mat-toolbar class="w-100 h-fit-content px-0 py-0 bg-transparent position-fixed top-0 z-index-5">
    <!-- INFO Inner Wrapper -->
    <div class="d-flex w-100 px-2 px-sm-3 px-md-4 px-lg-5 py-4">
        <!-- INFO logo -->
        <app-imole-logo class="my-auto" style="width: 132px; height: 36px;"></app-imole-logo>
        
        <!-- INFO Contact us button -->
        <a mat-flat-button class="ms-auto my-auto px-4" color="primary" [routerLink]="['/', 'get-in-touch']" routerLinkActive="d-none">Contact us!</a>
    </div>
</mat-toolbar>

<div class="pt-7 pt-sm-7 pt-md-0 overflow-auto" style="background-color: #FFFEF6; background: url(https://storage.googleapis.com/gci-static-assets/imole/e-1.png) no-repeat top right, #FFFEF6 url(https://storage.googleapis.com/gci-static-assets/imole/e-2.png) no-repeat bottom center;">
    <router-outlet></router-outlet>
</div>
