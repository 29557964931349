import { Component, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { RequestDemoService } from '../services/request-demo.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  public form: FormControl<any> = new FormControl(null, [
    Validators.required,
    Validators.email,
  ]);
  private readonly requestDemoService: RequestDemoService =
    inject(RequestDemoService);
  private readonly snackbar: MatSnackBar = inject(MatSnackBar);

  public sendDemoRequest() {
    this.requestDemoService.sendDemoRequest(this.form.getRawValue()).subscribe({
      next: () => {
        this.snackbar.open('Request has been sent successfully!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-primary', 'text-white'],
        });
      },
      error: (error) => {
        console.error(`[sendDemoRequest]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }
}
