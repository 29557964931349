import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APIBaseURLOption } from '../interfaces/api-base-url.interface';
import { API_BASE_URL_TOKEN } from '../providers/api-base-url.provider';

@Injectable({
  providedIn: 'root',
})
export class RequestDemoService {
  private readonly url!: string;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };
  public baseUrl!: string;

  constructor(
    @Inject(API_BASE_URL_TOKEN)
    private readonly apiBaseUrlProvider: APIBaseURLOption,
    private readonly httpClient: HttpClient
  ) {
    this.url = `${this.apiBaseUrlProvider.rootBase}`;
  }

  public sendDemoRequest(email: string) {
    const url = `${this.url}/email/request-demo`;

    return this.httpClient.post(
      url,
      { email },
      {
        headers: this.headers,
      }
    );
  }
}
