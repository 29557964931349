<a [routerLink]="['/']">
    <svg viewBox="0 0 510.07263 148.70134" fill="none" version="1.1"
        xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs18" />
        <path d="m 53.209221,74.346444 -14.9441,14.9442 H -2.545929e-8 v -29.8883 H 38.265121 Z" fill="black" id="path1"
            style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 89.290621,110.42834 v 38.273 h -29.8961 v -38.273 l 14.9441,-14.943996 h 0.0078 z" fill="black"
            id="path2" style="fill:#f9a01f;fill-opacity:1" />
        <path d="M 89.290621,-1.1581421e-8 V 38.265044 l -14.9442,14.9442 h -0.0078 l -14.9441,-14.9442 V -1.1581421e-8 Z"
            fill="black" id="path3" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 59.394221,38.256244 v 21.1448 h -21.1292 l -27.057,-27.0648 21.137,-21.1292 z" fill="black" id="path4"
            style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 59.394221,89.289044 v 21.137296 l -27.0492,27.065 -21.137,-21.145 27.057,-27.057296 z" fill="black"
            id="path5" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 148.69062,59.402344 v 29.8883 h -38.273 l -14.943999,-14.9442 14.943999,-14.9441 z" fill="black"
            id="path6" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 89.289121,110.42834 v 38.273 h -29.8961 v -38.273 l 14.9442,-14.943996 h 0.0078 z" fill="black"
            id="path7" style="fill:#f9a01f;fill-opacity:1" />
        <path d="M 89.289121,-1.1581421e-8 V 38.265044 l -14.9441,14.9442 h -0.0078 l -14.9442,-14.9442 V -1.1581421e-8 Z"
            fill="black" id="path8" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 137.48262,32.336244 -27.064,27.0648 H 89.289021 v -21.1448 l 27.056599,-27.0492 z" fill="black"
            id="path9" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 137.48262,116.34634 -21.137,21.145 -27.056599,-27.065 V 89.289044 h 21.129599 z" fill="black" id="path10"
            style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 215.61762,52.730444 h -17.265 v 68.798896 h 17.265 z" fill="black" id="path11"
            style="fill:#f9a01f;fill-opacity:1" />
        <path
            d="m 407.66462,62.418044 c -3.031,-3.0311 -6.636,-5.4034 -10.807,-7.1171 -4.175,-1.7136 -8.766,-2.5705 -13.773,-2.5705 -5.007,0 -9.604,0.8569 -13.774,2.5705 -4.175,1.7137 -7.776,4.086 -10.807,7.1171 -3.031,3.0311 -5.403,6.6565 -7.117,10.8763 -1.713,4.2197 -2.57,8.8307 -2.57,13.8379 0,5.0072 0.857,9.6232 2.57,13.838096 1.714,4.22 4.086,7.84 7.117,10.876 3.031,3.031 6.632,5.404 10.807,7.117 4.17,1.714 8.767,2.571 13.774,2.571 5.007,0 9.598,-0.857 13.773,-2.571 4.171,-1.713 7.776,-4.086 10.807,-7.117 3.031,-3.031 5.404,-6.656 7.117,-10.876 1.714,-4.214896 2.571,-8.830896 2.571,-13.838096 0,-5.0072 -0.857,-9.6232 -2.571,-13.8379 -1.713,-4.2198 -4.086,-7.8403 -7.117,-10.8763 z m -8.964,32.49 c -0.921,2.2882 -2.174,4.2198 -3.754,5.799296 -1.58,1.58 -3.408,2.794 -5.468,3.626 -2.065,0.837 -4.195,1.253 -6.394,1.253 -2.199,0 -4.438,-0.416 -6.459,-1.253 -2.02,-0.832 -3.823,-2.046 -5.403,-3.626 -1.58,-1.579496 -2.833,-3.511096 -3.754,-5.799296 -0.921,-2.2832 -1.382,-4.8784 -1.382,-7.7758 0,-2.8973 0.461,-5.4926 1.382,-7.7758 0.921,-2.2832 2.174,-4.2197 3.754,-5.7997 1.58,-1.5799 3.383,-2.7884 5.403,-3.6254 2.021,-0.832 4.171,-1.253 6.459,-1.253 2.288,0 4.329,0.416 6.394,1.253 2.065,0.837 3.888,2.0455 5.468,3.6254 1.58,1.58 2.833,3.5165 3.754,5.7997 0.921,2.2882 1.382,4.8785 1.382,7.7758 0,2.8974 -0.461,5.4926 -1.382,7.7758 z"
            fill="black" id="path12" style="fill:#f9a01f;fill-opacity:1" />
        <path
            d="m 335.17662,62.418044 c -2.155,-2.1594 -4.607,-3.987 -7.345,-5.4728 -1.11,-0.5993 -2.259,-1.149 -3.462,-1.6443 -4.176,-1.7136 -8.762,-2.5705 -13.774,-2.5705 -5.012,0 -9.603,0.8569 -13.779,2.5705 -1.198,0.4953 -2.352,1.0401 -3.457,1.6443 -2.743,1.4858 -5.19,3.3134 -7.349,5.4728 -0.372,0.3715 -0.738,0.7578 -1.09,1.149 -0.352,-0.3912 -0.718,-0.7726 -1.09,-1.149 -2.154,-2.1594 -4.606,-3.987 -7.345,-5.4728 -1.109,-0.5993 -2.258,-1.149 -3.462,-1.6443 -4.175,-1.7136 -8.761,-2.5705 -13.773,-2.5705 -5.012,0 -9.604,0.8569 -13.779,2.5705 -1.198,0.4953 -2.352,1.0401 -3.457,1.6443 -2.744,1.4858 -5.19,3.3134 -7.35,5.4728 -3.031,3.0311 -5.403,6.6565 -7.117,10.8713 -1.708,4.2197 -2.565,8.8307 -2.565,13.8429 v 34.402096 h 17.265 V 87.132244 c 0,-2.9023 0.466,-5.4876 1.382,-7.7758 0.921,-2.2832 2.174,-4.2148 3.754,-5.7997 1.585,-1.5799 3.388,-2.7884 5.404,-3.6254 2.02,-0.837 4.175,-1.253 6.463,-1.253 2.288,0 4.324,0.416 6.389,1.253 2.065,0.837 3.893,2.0455 5.473,3.6254 1.58,1.58 2.833,3.5115 3.754,5.7997 0.926,2.2882 1.382,4.8785 1.382,7.7758 v 34.402096 h 17.339 V 87.132244 c 0,-2.9023 0.466,-5.4876 1.382,-7.7758 0.921,-2.2832 2.174,-4.2148 3.754,-5.7997 1.585,-1.5799 3.388,-2.7884 5.404,-3.6254 2.02,-0.837 4.175,-1.253 6.463,-1.253 2.288,0 4.324,0.416 6.389,1.253 2.065,0.837 3.893,2.0455 5.473,3.6254 1.58,1.58 2.833,3.5115 3.754,5.7997 0.926,2.2882 1.382,4.8785 1.382,7.7758 v 34.402096 h 17.27 V 87.132244 c 0,-5.0072 -0.857,-9.6182 -2.57,-13.8429 -1.714,-4.2148 -4.091,-7.8402 -7.117,-10.8713 z"
            fill="black" id="path13" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 438.44262,27.160144 h -17.265 v 94.370196 h 17.265 z" fill="black" id="path14"
            style="fill:#f9a01f;fill-opacity:1" />
        <path
            d="m 510.07262,87.132244 c 0,-2.0157 -0.138,-3.9622 -0.421,-5.8442 -0.228,-1.5948 -0.559,-3.1351 -0.99,-4.6358 -0.322,-1.1441 -0.718,-2.2683 -1.154,-3.3629 -1.719,-4.2148 -4.091,-7.8402 -7.122,-10.8713 -3.036,-3.036 -6.637,-5.4034 -10.807,-7.1171 -0.936,-0.3863 -1.897,-0.7281 -2.883,-1.0252 -3.382,-1.0302 -7.008,-1.5453 -10.896,-1.5453 -2.971,0 -5.794,0.3022 -8.474,0.9064 -1.837,0.416 -3.601,0.9707 -5.299,1.6691 -4.175,1.7136 -7.781,4.081 -10.812,7.1171 -3.031,3.031 -5.404,6.6565 -7.117,10.8713 -0.446,1.0945 -0.832,2.2188 -1.154,3.3629 -0.441,1.5006 -0.768,3.0459 -0.996,4.6357 -0.282,1.8821 -0.421,3.8335 -0.421,5.8443 0,2.3476 0.188,4.611 0.575,6.7902 0.272,1.5898 0.649,3.1301 1.129,4.6358 0.262,0.8122 0.555,1.614096 0.872,2.412096 1.713,4.22 4.086,7.845 7.117,10.876 3.031,3.031 6.636,5.404 10.812,7.117 4.17,1.714 8.766,2.571 13.773,2.571 5.007,0 9.599,-0.857 13.774,-2.571 4.17,-1.713 7.771,-4.086 10.807,-7.117 3.031,-3.031 5.403,-6.656 7.122,-10.876 0.317,-0.793 0.609,-1.599896 0.871,-2.412096 h -18.914 c -0.213,0.307 -0.431,0.5943 -0.659,0.8816 -0.356,0.4455 -0.733,0.866496 -1.134,1.267496 -1.585,1.585 -3.403,2.789 -5.473,3.626 -2.065,0.837 -4.195,1.253 -6.389,1.253 -2.194,0 -4.433,-0.416 -6.458,-1.253 -2.026,-0.837 -3.824,-2.041 -5.409,-3.626 -0.648,-0.648 -1.258,-1.371496 -1.793,-2.149096 -0.767,-1.0995 -1.421,-2.313 -1.956,-3.6502 -0.134,-0.3219 -0.247,-0.6538 -0.366,-0.9905 h 49.676 c 0.381,-2.1793 0.569,-4.4427 0.569,-6.7903 z m -50.533,-5.8442 c 0.179,-0.6637 0.402,-1.3125 0.649,-1.9365 0.387,-0.9609 0.837,-1.8623 1.342,-2.6993 0.689,-1.149 1.491,-2.1792 2.407,-3.0955 1.585,-1.5799 3.383,-2.7884 5.409,-3.6254 1.605,-0.6636 3.288,-1.0599 5.062,-1.1985 0.455,-0.0347 0.921,-0.0545 1.396,-0.0545 2.199,0 4.329,0.416 6.389,1.253 2.071,0.837 3.888,2.0455 5.473,3.6254 0.921,0.9163 1.724,1.9465 2.407,3.0955 0.51,0.842 0.961,1.7434 1.347,2.6993 0.248,0.624 0.471,1.2679 0.649,1.9365 h -32.535 z"
            fill="black" id="path15" style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 199.14062,48.401244 -12.103,-21.2411 h 18.12 l 9.444,21.2411 z" fill="black" id="path16"
            style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 373.58662,48.401244 9.654,-21.2411 h 17.56 l -12.208,21.2411 z" fill="black" id="path17"
            style="fill:#f9a01f;fill-opacity:1" />
        <path d="m 469.27262,48.401244 -12.103,-21.2411 h 18.12 l 9.409,21.2411 z" fill="black" id="path18"
            style="fill:#f9a01f;fill-opacity:1" />
    </svg>
</a>
