import { InjectionToken } from '@angular/core';
import { API_BASE_URL_DEV } from '../constants/api.constant';
import { API_BASE_URL, ENVIRONMENTS } from '../constants/provider.constant';
import { APIBaseURLOption } from '../interfaces/api-base-url.interface';

export const API_BASE_URL_TOKEN = new InjectionToken<APIBaseURLOption>(
  API_BASE_URL
);
export const apiBaseURLProvider = {
  provide: API_BASE_URL_TOKEN,
  useFactory: (env: { production: boolean }): APIBaseURLOption => {
    return {
      rootBase: env.production
        ? 'https://api.imole.com.ng'
        : API_BASE_URL_DEV,
    };
  },
  deps: [ENVIRONMENTS],
};
