import { Component } from '@angular/core';

@Component({
  selector: 'app-imole-logo',
  templateUrl: './imole-logo.component.html',
  styleUrls: ['./imole-logo.component.scss']
})
export class ImoleLogoComponent {

}
