import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss'],
})
export class GetInTouchComponent {
  public form!: FormGroup;
  public to: string = 'hello@evryword.com.ng';
  public cc: string = 'olaleyeisrael@gmail.com';
  public subject: string = 'Reaching%20Out';
  public body: string = 'type%20your%20message%20here...';
  public emailLink: string = `mailto:${this.to}/?cc=${this.cc}&subject=${this.subject}`;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();
  }

  public getBody() {
    const data = this.form.getRawValue();

    this.emailLink =
      this.emailLink +
      '&body=' +
      [
        `Name: ${data.name} `,
        `Email: ${data.email} `,
        `Phone Number: ${data.phone} `,
        `${data.message}`,
      ].join('\n');
  }

  public initializeForm() {
    this.form = this.formBuilder.nonNullable.group({
      name: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
    });
  }
}
